import useSWR from "swr"
import { getSupportContact } from "../api/appApi"
import { handleResponseDataGetContent } from "utils/useResolvedV2"

export const useSupportContactSwr = () => {
    const swr = useSWR(`/apps/current/supportContact`, ()=>getSupportContact().then(handleResponseDataGetContent), 
    {
        refreshInterval: 0,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    })
    return swr
}