import { Box, BoxProps, Button, ButtonProps, Typography } from "@mui/material";
import { SimpleDialog, SimpleDialogButton } from "components/dialog/SimpleDialog";
import { getCurrencySign, PaymentPeriod } from "modules/picasso-modules/subscription/PaymentTypes";
import { ChoiceCards } from "modules/picasso-ui/form/ChoiceCards";
import { VerticalAlignBox } from "modules/picasso-ui/layout/VerticalAlignBox";
import { ElementLink, LinkButtonNormal } from "modules/picasso-ui/link/LinkNormal";
import { ChipStyled } from "modules/picasso-ui/present/chip/ChipStyled";
import { FormattedTypography } from "modules/picasso-ui/present/text/FormattedText";
import { Coupon, PaymentGateway, SimplePlanInfo, SimplePlanVariant } from "modules/yoio/payment/model/PaymentTypes";
import { useApp } from "modules/yoio/useApp";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { useEffect, useState } from "react";
import { FunctionComponent, useRef } from "react";
import { appRoutes } from "utils/appRoutes";
import { removeHtmlTags } from "utils/stringUtils";
import { useWorkspaceMy } from "../useWorkspace";
import { useSubscription } from "./useSubscriptionPlanComparison";
import CheckIcon from '@mui/icons-material/Check';
import { useAnalytics } from "modules/yoio/analytics/useAnalytics";
import { paddleChangeSubscription } from "modules/plinzip/api/workspacesApi";
import { hideAppProgressIndicator, showAppProgressIndicator } from "modules/picasso-ui/main/AppProgressIndicator";
import alerts from "modules/yoio/state/alerts";
import { notify } from "modules/yoio/errorsService";
import { AppPlanComparisonViewWithLandingPageSettings } from "modules/landingPage/component/pricing/AppPlanComparisonView";
import { fromApiTimeToBrowserTimeV2 } from "utils/dateUtils";
import { notNullNotUndefined } from "utils/objectUtils";
import { useSupportContactSwr } from "modules/yoio/apps/useSupportContactSwr";
import { LinearProgress } from "@mui/material";

export const PlanPickerDialogButton: FunctionComponent<ButtonProps> = ({children, ...props}) => {
    const { component, openDialog } = usePlanPickerDialog()

    return <><Button {...props} onClick={openDialog}>{children || 'Upgrade now'}</Button>{component}</>
}

export const usePlanPickerDialog = () => {

    const { workspaceId } = useWorkspaceMy()

    const { hasPermissionCreateOrEdit } = useSubscription(workspaceId)

    const [open, setOpen] = useState<boolean>(false)

    const openDialog = () => {
        if (!hasPermissionCreateOrEdit) {
            alerts.error('you cannot change the subscription.')
            notify(new Error('you cannot change the subscription shown to user.'))
        } else {
            setOpen(true)
        }
    }

    const component = <PlanPickerDialog open={open} onClose={()=>setOpen(false)}/>

    return { component, openDialog }
}

export interface PlanPickerDialogProps {
    open: boolean
    onClose?: ()=>void
}

export const PlanPickerDialog: FunctionComponent<PlanPickerDialogProps> = ({open, onClose}) => {

    const wasOpen = useRef<boolean>(false)

    useEffect(()=>{
        if (open && !wasOpen.current) {
            wasOpen.current = true
        }
    },[open])

    return (
        <>
            {/** @ts-ignore */}
            <SimpleDialog showTitleCloseButton open={open} onClose={onClose} hideActions fullWidth maxWidth="md" positionTop showCloseButton contentNoPadding sx={{padding: 0}}>
                {open || wasOpen.current ? <PlanPickerView showImage /> : null}
            </SimpleDialog>
        </>
    )

}

export interface PlanPickerViewProps {
    showImage?: boolean
}

// eslint-disable-next-line no-unused-vars
enum Display {
    // eslint-disable-next-line no-unused-vars
    planSelect = 'planSelect',
    // eslint-disable-next-line no-unused-vars
    paidToPaidUpgrade = 'paidToPaidUpgrade',
}

export const PlanPickerView: FunctionComponent<PlanPickerViewProps> = ({showImage}) => {

    const { trackAccessEventUser } = useAnalytics()

    const { app } = useApp()

    const [display, setDisplay] = useState<Display>(Display.planSelect)
    const [planSelected, setPlanSelected] = useState<SimplePlanInfo>()
    const [variantSelected, setVariantSelected] = useState<SimplePlanVariant>()

    //@ts-ignore
    const { t } = useTranslation(app.i18nNamespaces)

    /**
     * Events
     */
    useEffect(()=>{
        trackAccessEventUser('plan picker dialog viewed')
    },[])

    useEffect(()=>{
        if (display === Display.paidToPaidUpgrade) {
            trackAccessEventUser('plan picker dialog - paidToPaidUpgrade - viewed')
        }
    },[display])

    //@ts-ignore
    const i18nNamespaceDefault = app.i18nNamespaceDefault

    //@ts-ignore
    const upgradeIncentiveTitle = t(`${i18nNamespaceDefault}:app.plans.upgradeIncentive.title`)

    const { workspaceId } = useWorkspaceMy()

    const { plansAvailableForUpgrade, isPaid, subscriptionInfo } = useSubscription(workspaceId)

    const planOptions = plansAvailableForUpgrade?.map(p=>({
        title: p.name,
        key: p.key,
        value: p.key,
    }))

    const planVariants = planSelected 
        ? plansAvailableForUpgrade?.find(p=>p.key===planSelected.key)?.variants
        : null

    // Rank order to show in
    const periodRank = [PaymentPeriod.annually, PaymentPeriod.monthly]

    const anyVariantWithOneCurrency = planVariants?.find(v=>v.priceTotal && Object.keys(v.priceTotal).length === 1)

    const currency = anyVariantWithOneCurrency ? Object.keys(anyVariantWithOneCurrency.priceTotal)[0] : null

    const planVariantOptions = planVariants?.sort((a,b)=>{
            const result = periodRank.indexOf(a.period)-periodRank.indexOf(b.period)
            return result
        })
        .map(v=>{

            return {
                title: <Box>
                        <Typography component="div" fontSize="14px" fontWeight="600" textTransform="capitalize">{t(`yoio:access.plans.paymentPeriod.${v.period}.title`)}</Typography>
                        <VerticalAlignBox spacing="4px">
                            <Typography component="div" fontSize="14px" fontWeight="400" color="text.secondary">{v.priceMonthly[currency]}{getCurrencySign(currency)} {t(`yoio:access.plans.paymentPeriod.monthly.titlePer`)}</Typography>
                            {v.period === PaymentPeriod.annually && <VerticalAlignBox><ChipStyled 
                                        label="Save 20%" 
                                        color={'success'}
                                        sx={{
                                            borderRadius: '4px',
                                            marginLeft: '12px',
                                            color: '#339770',
                                            backgroundColor: '#d5f4e8',
                                            fontSize: '14px',
                                            cursor: 'pointer',
                                        }}
                            /></VerticalAlignBox>}
                        </VerticalAlignBox>
                    </Box>,
                value: v.period,
                key: v.period
            }
        })

    const switchPlanChoice = (planKey: string) => {
        notNullNotUndefined(planKey)
        const plan = plansAvailableForUpgrade.find(p=>p.key === planKey)
        const variant = plan.variants?.find(v=>v.period===PaymentPeriod.annually) || plan.variants?.[0] || null
        setPlanSelected(plan)
        setVariantSelected(variant)
    }

    useEffect(()=>{
        if (!plansAvailableForUpgrade) {
            return;
        }
        if (!planSelected) {
            const planPreSelected = plansAvailableForUpgrade.find(p=>p.key.toLowerCase().includes('premium')) || plansAvailableForUpgrade[0]
            if (planPreSelected) {
                switchPlanChoice(planPreSelected.key)
            }
        }
    }, [plansAvailableForUpgrade])

    /**
     * User actions
     */

    const handleContinueToUpgradeClick = () => {
        setDisplay(Display.paidToPaidUpgrade)
    }

    const handleBackToPlanSelectClick = () => {
        setDisplay(Display.planSelect)
    }

    const handleSubmitPaidUpgrade = () => {
        showAppProgressIndicator('Upgrading...')
        paddleChangeSubscription(workspaceId, planSelected.key, variantSelected.period)
            .then(()=>{
                window.location.reload()
            })
            .catch(()=>{
                hideAppProgressIndicator()
            })
    }

    /**
     * Render
     */

    const isNewSubscription = isPaid === false

    const isUpgradeFromPaidToPaid = isPaid === true

    const isPaymentGatewaySupportedForUpgrade = subscriptionInfo.paymentGateway === PaymentGateway.paddle

    const getReccurringPaymentInfoText = ():string => {
        return t('yoio:access.plans.subscription.recurringPaymentInfo', {
            price: variantSelected.priceTotal[currency],
            currency: getCurrencySign(currency),
            periodAdverb: t(`yoio:access.plans.paymentPeriod.${variantSelected.period}.title`)
        })
    }

    const renderDisplayPlanSelect = () => {

        const planVariantSelectedEffective = variantSelected ? planVariantOptions?.find(p=>p.key===variantSelected.period) : null

        const planSelectedEffective = planSelected ? planOptions?.find(p=>p.key===planSelected.key) : null

        return (
            <>
                <Typography fontSize="1.6em" fontWeight="600" mb={1}>Upgrade</Typography>

                {/** @ts-ignore */}
                <FormattedTypography color="text.secondary">{removeHtmlTags(upgradeIncentiveTitle)}</FormattedTypography>

                {planOptions?.length > 0 && planSelectedEffective && 
                    <Box mt={3}>
                        <ChoiceCards 
                            selected={planSelectedEffective ? [planSelectedEffective] : null}
                            single 
                            minSelected={1}
                            choiceCardVariant="box"
                            showCheckbox
                            checkboxPosition="right"
                            checkboxStyle="standard"
                            checkboxColor="primary"
                            options={planOptions}
                            styling={{
                                choiceCard: {
                                    height: '62px',
                                    width: '145px',
                                    opacity: 0.5,
                                },
                                choiceCardSelected: {
                                    opacity: 1,
                                },
                                title: {
                                    fontWeight: '600',
                                    fontSize: '14px',
                                },
                            }}
                            onChange={(options)=>{
                                const choice = options.find(o=>o.isSelected)
                                switchPlanChoice(choice.value)
                            }}
                        />
                    </Box>
                }

                {plansAvailableForUpgrade?.length > 0 && 
                    <Box mt={1}>
                        <SimpleDialogButton 
                            renderToggleButton={(onClick)=>{

                                const handleClick = () => {
                                    trackAccessEventUser('plan picker dialog - compare plans clicked')
                                    return onClick()
                                }

                                //@ts-ignore
                                return <LinkButtonNormal onClick={handleClick} textSx={{color: 'text.secondary', fontSize: '12px'} }>Compare plans</LinkButtonNormal>
                            }} 
                            dialogProps={{ fullWidth: false, maxWidth: 'lg', showTitleCloseButton: true, noScroll: true, hideActions: true }}
                            >
                                {/**
                                 * no custom plans because no information what do to for custom plans
                                 */}
                                {(open, wasOpen, handleDialogClose)=>open 
                                    ? <Box style={{height: '65vh', paddingTop: '20px', width: `calc(450px + (${plansAvailableForUpgrade?.length} * 250px))`, maxWidth: '100%'}}>
                                        <AppPlanComparisonViewWithLandingPageSettings 
                                            includePaidPlansOnly includeCustomPlans={false} subscribableForWorkspace={workspaceId} stickyPlans 
                                            onPlanCtaClick={(plan)=>{
                                                switchPlanChoice(plan.key)
                                                handleDialogClose()
                                            }}
                                            // My plan will be shown, but no cta button
                                            showCtaButton={(p:SimplePlanInfo)=>subscriptionInfo?.plan?.key!==p.key}
                                    />
                                    </Box>
                                    : null
                                }
                        </SimpleDialogButton>
                    </Box>
                }

                {plansAvailableForUpgrade?.length === 0 && <Box mt={12}><NoPlansEmptyState /></Box>}
                
                {planVariantOptions && planVariantSelectedEffective && (
                    <>
                        <Typography component="div" fontWeight="600" mt={6}>Billing cycle</Typography>
                        <Box mt={2}>
                            <ChoiceCards 
                                single 
                                minSelected={1}
                                choiceCardVariant="box"
                                showCheckbox
                                checkboxPosition="right"
                                checkboxStyle="standard"
                                checkboxColor="primary"
                                options={planVariantOptions}
                                selected={planVariantSelectedEffective ? [planVariantSelectedEffective] : null}
                                style={{
                                    flexDirection: 'column',
                                    alignItems: 'stretch',
                                    gap: '16px',
                                }}
                                styling={{
                                    choiceCard: {
                                        height: '65px',
                                        //width: '200px'
                                        opacity: 0.5,
                                        alignItems: 'center',
                                        width: 'initial',
                                        maxWidth: '100%',
                                        '& > div': {
                                            paddingTop: '0',
                                            paddingBottom: '0',
                                        },
                                    },
                                    choiceCardSelected: {
                                        opacity: 1,
                                    },
                                    title: {
                                        fontWeight: '600',
                                        fontSize: '14px',
                                    },
                                }}
                                onChange={(options)=>{
                                    const choice = options.find(o=>o.isSelected)
                                    setVariantSelected(planVariants.find(v=>v.period === choice.value))
                                }}
                            />
                        </Box>
                    </>
                )}
                
                {planSelected && variantSelected && currency && (
                    <>
                        {/** @ts-ignore */}
                        <Typography mt={6} component="div" fontSize="14px">
                            {isUpgradeFromPaidToPaid && <>After upgrade to the new plan: {getReccurringPaymentInfoText()} We will apply a pro-rated credit for your existing subscription, hence you only pay the additional cost.</>}
                            {!isUpgradeFromPaidToPaid && <>{getReccurringPaymentInfoText()}<br/><Typography component="span" fontSize="12px" color="text.secondary">{t('yoio:access.plans.subscription.priceVatInfo')}</Typography></>}
                        </Typography>

                        {isNewSubscription && (
                            <>
                                {subscriptionInfo?.couponOffer && <CouponDisplay coupon={subscriptionInfo.couponOffer} mt={3} mb={3} />}
                                <Box mt={3}>
                                    <ElementLink href={appRoutes.yoio.subscription.checkoutPaddle.buildPath(planSelected.key, variantSelected.period)}>
                                        <Button color="primary" variant="contained" fullWidth size="large">
                                            {t('yoio:access.plans.payment.action.continueToCheckout.buttonText')}
                                        </Button>
                                    </ElementLink>
                                </Box>
                            </>
                        )}

                        {isUpgradeFromPaidToPaid && (
                            <>
                                {isPaymentGatewaySupportedForUpgrade
                                    ? <Box mt={3}>
                                        <Button color="primary" variant="contained" fullWidth onClick={handleContinueToUpgradeClick}>Continue to upgrade</Button>
                                    </Box> 
                                    : <Typography mt={6} fontWeight={600}>Your account type is currently not supported for an upgrade. Please contact our support by email and we will perform the upgrade for you.</Typography>
                                }
                            </>
                        )}
                    </>
                )}
            </>
            )
    }

    const renderDisplayPaidToPaidUpgrade = () => {

        return (
            <>
                <Typography fontSize="1.6em" fontWeight="600" mb={1}>Upgrade to {planSelected?.name}</Typography>

                <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="space-between">
                    {/** @ts-ignore */}
                    <FormattedTypography mt={6} component="div" fontSize="14px">{getReccurringPaymentInfoText()}</FormattedTypography>
                    
                    <Typography mt={3}>
                        You will be charged the shown price + tax if applicable.
                    </Typography>
                    <Typography>
                        If applicable, we will consider a tax exemption accordingly if you provided a VAT with your original subscription.
                    </Typography>

                    <Typography fontWeight={600} mt={6} mb={1}>
                        Current billing period costs
                    </Typography>
                    <Typography>
                        Already paid cost for the current billing period is credited to you pro-rate. 
                        For the remaining time of the current billing period you only pay the fractional subscription costs.
                        You will be charged additional costs immediately on upgrade.
                    </Typography>

                    <VerticalAlignBox justifyContent="space-between" mt={6}>
                        {/** @ts-ignore */}
                        <LinkButtonNormal onClick={handleBackToPlanSelectClick} textSx={{color: 'text.secondary'}}>Select another plan</LinkButtonNormal>
                        <Button color="primary" variant="contained" onClick={handleSubmitPaidUpgrade}>Perform paid upgrade now</Button>
                    </VerticalAlignBox>
                </Box>
            </>
        )
    }

    if (!currency) {
        return <LinearProgress />
    }

    return (<Box 
        gap="24px"
        display="flex" 
        sx={theme=>({
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
        })}
        >

        {showImage && (
            //@ts-ignore
            <Box flex="1" display="flex" justifyContent="flex-start" alignItems="center" p={3} pt={10} flexDirection="column" backgroundColor="#eef2f7">
                <Image src="/yoio/upgrade-default-nobg.svg" width="150px" height="150px" unoptimized/>
                <Box
                    sx={theme=>({
                        [theme.breakpoints.down('sm')]: {
                            display: 'none',
                        },
                    })}
                    >
                    <Typography component="div" fontSize="1.2em" fontWeight="600" mt={3} mb={1}>Achieve more</Typography>
                    <VerticalAlignBox spacing={1} color="text.secondary"><CheckIcon sx={{color: '#87d2b5'}} /> <Typography component="div">More participants</Typography></VerticalAlignBox>
                    <VerticalAlignBox spacing={1} color="text.secondary"><CheckIcon sx={{color: '#87d2b5'}} /> <Typography component="div">Advanced settings</Typography></VerticalAlignBox>
                    <VerticalAlignBox spacing={1} color="text.secondary"><CheckIcon sx={{color: '#87d2b5'}} /> <Typography component="div">More filter options</Typography></VerticalAlignBox>
                    <VerticalAlignBox spacing={1} color="text.secondary"><CheckIcon sx={{color: '#87d2b5'}} /> <Typography component="div">Unlimited data retention</Typography></VerticalAlignBox>
                    <VerticalAlignBox spacing={1} color="text.secondary"><CheckIcon sx={{color: '#87d2b5'}} /> <Typography component="div">More admin users</Typography></VerticalAlignBox>
                </Box>
            </Box>
        )}

        <Box flex="2" p={3} display="flex" flexDirection="column">
            <Box>
                {display === Display.planSelect && renderDisplayPlanSelect()}
            </Box>
            {display === Display.paidToPaidUpgrade && renderDisplayPaidToPaidUpgrade()}
        </Box>
    </Box>)

}

const CouponDisplay = ({coupon, ...props}: BoxProps & {coupon: Coupon}) => {
    notNullNotUndefined(coupon)

    const [open, setOpen] = useState<boolean>(false)

    return (
        <Box p={1} sx={{ border: 1, borderStyle: 'dashed', borderRadius: '4px'}} {...props}>
            <VerticalAlignBox justifyContent="space-between">
                <div>
                    <Typography fontSize="2.1em" fontWeight="700">{coupon.percentage}% OFF</Typography>
                    <Typography color="text.secondary" fontSize="0.8em">if you subscribe until {fromApiTimeToBrowserTimeV2(coupon.expiresAt)}</Typography>
                </div>
                {/** @ts-ignore */}
                <LinkButtonNormal onClick={()=>setOpen(cur=>!cur)}>View coupon</LinkButtonNormal>
            </VerticalAlignBox>
            {open && 
                <Box mt={1}>
                    <Typography color="text.secondary">Welcome offer. Get an additional {coupon.percentage}% discount, as long as you are subscribed. Applicable to Premium and Business. Discount will be applied to the price shown above. Use this coupon during checkout:</Typography>
                    <Typography color="primary.main" fontWeight="600" mt={1} component="div" fontSize="1.3em">{coupon.code}</Typography>
                </Box>
            }
        </Box>
    )

}

const NoPlansEmptyState = () => {

    const { data:supportContactEmail } = useSupportContactSwr()

    return (
        <Box>
            <Typography fontSize="22px" mb={1}>You are on the highest plan. Need more?</Typography>
            <Typography fontSize="14px" mb={2} color="text.secondary">Contact us to get higher usage volumes and exclusive features.</Typography>
            {supportContactEmail && 
                    <ElementLink href={`mailto:${supportContactEmail}`}>
                        <Button variant="contained" size="large">Contact us</Button>
                    </ElementLink>
            }
            {!supportContactEmail &&
                <Typography>Please contact our support.</Typography>
            }
        </Box>
    )
    
}