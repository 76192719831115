import { getWorkspaceSubscriptionInfo, getWorkspaceSubscriptionPlanComparison, getWorkspaceSubscriptionPlanComparisonCurrentPlan } from "modules/plinzip/api/workspacesApi"
import { hasPermissionInEac } from "modules/react-auth/component/border/PermissionEntityBorder"
import { getWorkspaceSubscriptionInfoFetchKey } from "modules/yoio/YoioConfig"
import { SubscriptionInfoDto } from "modules/yoio/payment/model/PaymentTypes"
import useSWR, { SWRResponse } from "swr"
import { notNullNotUndefined } from "utils/objectUtils"
import { handleResponseDataGetContent, useResolvedItemV2 } from "utils/useResolvedV2"

export const useSubscription = (workspaceId?: string) => {

    const subscriptionInfoResolved = useResolvedItemV2(workspaceId ? getWorkspaceSubscriptionInfoFetchKey(workspaceId):null, () => getWorkspaceSubscriptionInfo(workspaceId))

    const subscriptionPlanComparisonResolved = useResolvedItemV2(workspaceId ? `/workspaces/${workspaceId}/subscriptionPlanComparison`:null, () => getWorkspaceSubscriptionPlanComparison(workspaceId))

    const subscriptionPlanComparisonCurrentPlanResolved = useResolvedItemV2(workspaceId ? `/workspaces/${workspaceId}/subscriptionPlanComparisonCurrentPlan`:null, () => getWorkspaceSubscriptionPlanComparisonCurrentPlan(workspaceId))

    const myPlanKey = subscriptionPlanComparisonCurrentPlanResolved.item?.myPlan

    const isPaid = subscriptionInfoResolved.item?.plan?.free === false

    // Also wait for subscriptionInfoResolved.item for filtering a few code lines later
    let plansAvailableForUpgrade = subscriptionInfoResolved.item && myPlanKey && subscriptionPlanComparisonResolved.item 
        ? subscriptionPlanComparisonResolved.item?.plans?.filter(p=>p.key !== myPlanKey && p.free !== true)
        : null

    // Only show higher plans. For "changing" plan, add another variable plansAvailableForChange later
    if (isPaid && plansAvailableForUpgrade) {
        // Only show plans where the same variant has a higher price
        const currentPrice = subscriptionInfoResolved.item.plan.price
        const currentPaymentPeriod = subscriptionInfoResolved.item.plan.paymentPeriod
        plansAvailableForUpgrade = plansAvailableForUpgrade.filter(p=>p.variants.some(v=>v.period===currentPaymentPeriod&&v.priceMonthly['usd']>currentPrice))
    }

    const hasPermissionCreate = subscriptionInfoResolved.item?.subscriptionEac 
        ? hasPermissionInEac(subscriptionInfoResolved.item?.subscriptionEac, 'subscriptionCreate')
        : null // important, because null!=false

    const hasPermissionEdit = subscriptionInfoResolved.item?.subscriptionEac 
            ? hasPermissionInEac(subscriptionInfoResolved.item?.subscriptionEac, 'subscriptionEdit')
            : null // important, because null!=false

    return {
        subscriptionPlanComparison: subscriptionPlanComparisonResolved.item || null,
        subscriptionPlanComparisonCurrentPlan: subscriptionPlanComparisonCurrentPlanResolved.item || null,
        plansAvailableForUpgrade,
        subscriptionInfo: subscriptionInfoResolved.item as SubscriptionInfoDto,
        hasPermissionEdit,
        isPaid,
        hasPermissionCreateOrEdit: (subscriptionInfoResolved.item?.plan?.free === true && hasPermissionCreate) || hasPermissionEdit
    }

}

export interface UseWorkspaceFeaturesReturn {
    hasFeature: (key:string)=>boolean
}

export const useWorkspaceFeatures = (workspaceId?: string):UseWorkspaceFeaturesReturn => {

    const subscriptionInfoResolved = useResolvedItemV2(workspaceId ? getWorkspaceSubscriptionInfoFetchKey(workspaceId):null, () => getWorkspaceSubscriptionInfo(workspaceId))

    const hasFeature = (key) => {
        notNullNotUndefined(key)
        if (!subscriptionInfoResolved.item) {
            return null
        }
        return Object.keys(subscriptionInfoResolved.item.featuresEffective).includes(key)
    }

    return { hasFeature }
    
}

export const useSubscriptionInfoSwr = (workspaceId?: string):SWRResponse<SubscriptionInfoDto> => {
    const swr = useSWR<SubscriptionInfoDto>(workspaceId ? getWorkspaceSubscriptionInfoFetchKey(workspaceId):null, () => getWorkspaceSubscriptionInfo(workspaceId).then(handleResponseDataGetContent))
    return swr
}