import { PaymentPeriod } from "modules/picasso-modules/subscription/PaymentTypes"

export type SubscriptionInfoDto = {
    plan: PlanInfoDto
    featuresEffective: Map<string,FeatureInfoDto>
    paddleData?: any
    paymentGateway: PaymentGateway
    couponOffer?: Coupon
}

export type Coupon = {
    code: string
    /**
     * 1-100
     * 30 -> 30 percent
     */
    percentage?: number
    /**
     * String or timestamp seconds
     */
    expiresAt: string | number
}

export type FeatureInfoDto = {

    key: string

    title?: string

    info?: string

    category?: string

    quota?: number

}

export type FeatureInfoComparsion = {

    settings: FeatureDisplaySettings

    featureInfo: FeatureInfoDto

    quotaPerPlan: Map<string, number>

    quotaMyTotal: number

    quotaMyAdditional: number

}

export type FeatureDisplaySettings = {

    showUnlimitedAsIncluded: boolean

}

export type PlanInfoDto = {

    free: boolean

    key: string

    name: string

    cancelAt: number

    trialEndsAt: string

    trialEndedAt: string

    paymentPeriod?: PaymentPeriod

    partner: boolean

    expired: boolean

}

export type SubscriptionPlanComparisonDto = {

    myPlan: string

    highestPlan: string

    plans: SimplePlanInfo[]

    features: FeatureInfoComparsion[]

}

export type SimplePlanInfo = {

    key: string

    name: string

    settings: PlanDisplaySettings

    variants?: SimplePlanVariant[]

    free?: boolean

}

export type SimplePlanVariant = {

    period: PaymentPeriod
    priceMonthly: { [currency: string]: [value: number] },
    priceTotal:  { [currency: string]: [value: number] },
    paddlePlanId: number

}

export type PlanDisplaySettings = {

    recommended: boolean

}

export enum PaymentGateway {
    stripe = 'stripe', 
    paddle = 'paddle',
}

export interface PaymentPaddleSettings {
    vendorId: number
    guestOrderAllowed?: boolean
    referenceId: string
}



