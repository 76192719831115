import { Box, Card, TextField, Tooltip, Typography } from "@mui/material";
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import Image from "next/image";
import { useState } from "react";
import { isHavingValue, isNullOrUndefined, orElse } from "utils/objectUtils";
import { MultilineTypography } from "../present/MultilineText";
import CheckIcon from '@mui/icons-material/Check';
import { useEffect } from "react";
import { isString } from "utils/stringUtils";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { tooltipClasses } from "@mui/material";
import styled from "@emotion/styled";

/**
 * @param {*} choiceCardVariant
 *         'simple' 'simpleNarrow' 'box' 'rows'
 * 
 * @param {*} onChange 
 *      onChange passes all choice options as the first param
 *      You need to check the choice options for .isSelected===true and handle accordingly
 * @param {*} styling
 *      styling.choiceCard
 *      styling.title
 *      styling.image
 *      styling.checkboxIcon
 * @returns 
 */
export const ChoiceCards = ({options, single, onChange, tooltipPlacement, defaultSelected, selected, onClick, showIcons, styling, choiceCardVariant, choiceCardComponent, choiceCardVerticalAlign, minSelected, display, style, smallImage, imageProps, showCheckbox, checkboxPosition, checkboxStyle, checkboxColor, gap}) => {

    showCheckbox = orElse(showCheckbox, true)

    checkboxPosition = checkboxPosition || 'left'

    checkboxStyle = checkboxStyle || 'circle'

    checkboxColor = checkboxColor || 'green'

    const checkboxIconStyle = {}

    if (options?.every(o=>isNullOrUndefined(o.key)) && options?.every(o=>isHavingValue(o.value))) {
        options = options.map(o=>({...o, key: o.value}))
    }

    const buildInitialOption = (o) => {
        if (defaultSelected) {
            // The intention of this line is to find the equal option by 'key' and 'value', but key has precedence.
            let fromDefault = defaultSelected.find(i => (i.key !== undefined && i.key === o.key) || i.value === o.value);
            if (fromDefault) {
                let oExtended = {...o, isSelected: true };
                if (o.customInput===true && fromDefault.touched === true) {
                    oExtended.defaultValue = fromDefault.value;
                    oExtended.value = fromDefault.value;
                }
                return oExtended;
            }
        }
        return {...o, isSelected: false }
    }

    const [optionsLocal, setOptionsLocal] = useState(options.map(o=>(buildInitialOption(o))));

    useEffect(()=>{
        if (!selected) {
            return;
        }

        setOptionsLocal(options.map(o=>({
            ...o,
            isSelected: selected.some(s=>s.key===o.key)
        })))
    },[selected])

    const handleClick = (o, checked) => {
        if (!o.key) {
            throw Error('specify a key for each option');
        }

        if (onClick) {
            onClick(o)
        }

        let updated = optionsLocal.map(o=>({...o}))

        if (checked === true && single === true && updated.filter(i=>i.isSelected).length >= 1) {
            //reset all if single mode
            updated.forEach(i => i.isSelected = false);
        }
        
        updated.find(i=>i.key === o.key).isSelected = checked;

        if (isHavingValue(minSelected)) {
            if (updated.filter(o=>o.isSelected).length < minSelected) {
              //do nothing
                return;
            }
        }

        setOptionsLocal(updated);

        if(onChange) {
            onChange(updated);
        }
    };

    const handleCustomInputChange = (o, val)  => {
        if (!o.key) {
            throw Error('specify a key for each option');
        }
        let updated = [...optionsLocal];
        let opt = updated.find(i=>i.key === o.key);
        opt.value = val;
        opt.touched = true;
        setOptionsLocal(updated);
        
        if(onChange) {
            onChange(updated);
        }
    }
    
    return (
        <Box display={display||"flex"} flexWrap="wrap" gap={gap??'24px'} sx={style}>
            <ChoiceCardsOptionsInner 
                options={optionsLocal} 
                onClick={handleClick} 
                onCustomInputChange={handleCustomInputChange} 
                showIcons={showIcons} 
                styling={styling}
                choiceCardVariant={choiceCardVariant}
                smallImage={smallImage}
                imageProps={imageProps}
                component={choiceCardComponent}
                showCheckbox={showCheckbox}
                checkboxPosition={checkboxPosition}
                checkboxStyle={checkboxStyle}
                checkboxColor={checkboxColor}
                checkboxIconStyle={checkboxIconStyle}
                tooltipPlacement={tooltipPlacement}
                verticalAlign={choiceCardVerticalAlign}
                />
        </Box>
    )

}

export const  ChoiceCardsOptionsInner = ({options, onClick, onCustomInputChange, showIcons, styling, choiceCardVariant, smallImage, component, imageProps, showCheckbox, checkboxPosition, checkboxStyle, checkboxColor, checkboxIconStyle, tooltipPlacement, verticalAlign}) => {

    const renderOptions = () => {
        return options.map((o,i)=><ChoiceCardItem
            variant={choiceCardVariant}
            component={component}
            key={i} {...o} 
            showIcon={showIcons}
            onClick={(checked)=>onClick(o, checked)}
            styling={styling}
            imageSrc={o.imageSrc}
            imageWidth={o.imageWidth}
            imageHeight={o.imageHeight}
            disabled={o.disabled}
            description={o.description}
            afterContent={o.afterContent}
            onCustomInputChange={(val)=>onCustomInputChange(o, val)} 
            smallImage={smallImage}    
            imageProps={imageProps}
            showCheckbox={showCheckbox}
            checkboxPosition={checkboxPosition}
            checkboxStyle={checkboxStyle}
            checkboxColor={checkboxColor}
            checkboxIconStyle={checkboxIconStyle}
            tooltipPlacement={tooltipPlacement}
            verticalAlign={verticalAlign}
        />);
    }

    return (
        <>{renderOptions()}</>
    );

}


/**
 * 
 * @param showIcon: refers to the checkbox icon
 * @param imageSrc: refers to an image for the option
 * 
 * @returns 
 */
export const ChoiceCardItem = ({title, subtitle, tooltip, tooltipPlacement, onClick, isSelected, customInput, defaultValue, onCustomInputChange, 
    showIcon, styling, variant, component, imageSrc, imageWidth, imageHeight, disabled, description, afterContent, smallImage, imageProps, showCheckbox, checkboxPosition, checkboxStyle, checkboxColor, checkboxIconStyle, verticalAlign}) => {

    variant = variant || 'simple'

    component = component || 'card'

    const choiceCardSx = [{
        transition: '0.3s',
        userSelect: 'none',
    }]

    if (component === 'card') {
        choiceCardSx.push({
            boxShadow: '0 4px 20px 0 rgb(25 55 100 / 5%)',
            border: '1px solid rgb(236 238 241)',
        })
    }

    if (variant === 'simple' || variant === 'simpleNarrow') {
        choiceCardSx.push({
            '& .cho__checkboxWrapper': {
                marginTop: '4px',
            }
        })
    }
    
    const cssMap = {
        box: ()=>({
            display: 'flex',
            width: '328px',
            height: '210px',
            marginTop: '0 !important',
        }),
        selectable: ()=>({
            '&:hover': {
                cursor: 'pointer',
                //transform: 'translateY(-6px)',
                border: '1px solid rgb(185 197 216)',
            },
        }),
        disabled: {
            opacity: '0.46',
            cursor: 'auto',
        },
        simple: (theme)=>({
            '& > div': {
               paddingTop:  theme.spacing(2.2),
               paddingBottom:  theme.spacing(3),
            },
            '& + $choiceCard': {
                marginTop: theme.spacing(4),
            },
        }),
        simpleNarrow: (theme)=>({
            '& > div': {
                paddingTop:  theme.spacing(0.3),
                paddingBottom:  theme.spacing(0.5),
             },
            '& + $choiceCard': {
                marginTop: theme.spacing(2),
            },
        }),
        checked: (theme)=>({
            borderColor: theme.palette.primary.main,
            '&:hover': {
                borderColor: theme.palette.primary.main + ' !important',
            }
        }),
    }

    if (isSelected) {
        choiceCardSx.push(cssMap.checked)
    }
    if (variant) {
        if (cssMap[variant]) {
            choiceCardSx.push(cssMap[variant])
        }
    }

    if (styling?.choiceCard) {
        choiceCardSx.push(styling.choiceCard)
    }
    if (isSelected && styling?.choiceCardSelected) {
        choiceCardSx.push(styling.choiceCardSelected)
    }

    if (disabled) {
        choiceCardSx.push(cssMap.disabled)
    } else {
        choiceCardSx.push(cssMap.selectable)
    }


    const handleClick = () => {
        if (disabled) {
            return;
        }
        if(onClick) {
            onClick(!isSelected);
        }
    }

    const handleTextFieldClick = (e) => {
        e.stopPropagation();
    };

    const titleSx = [{
        fontSize: '14px',
    }]
    if (styling?.title) {
        titleSx.push(styling.title)
    }
   
    const components = {
        card: {
            component: Card,
            props: {
                variant: 'outlined',
                sx: choiceCardSx
            },
            checkboxIconStyle: {
                transform: "scale(1.3)",
            },
        },
        div: {
            component: Box,
            props: {
                sx: [{
                    cursor: 'pointer',
                    userSelect: 'none',
                    '& .cho__choiceItem': {
                        padding: 0,
                        [`& .${checkboxClasses.root}`]: {
                            padding: 0,
                            marginRight: '8px',
                        },
                    },
                    '& .cho__titleWrapper': {
                        paddingBottom: '1px',
                    },
                }]
            }
        },
    }

    const wrapper = components[component]
    const WrapperComponent = wrapper.component //reateElement(Card)

    const getCheckboxIcon = (checked) => {
        if (checkboxStyle === 'standard') {
            return checked ? <CheckIcon style={{fontSize: '18px' }} /> : <></>
        }
        else if (checkboxStyle === 'circle') {
            return checked ? <CircleCheckedFilled style={wrapper.checkboxIconStyle}/> : <CircleUnchecked style={wrapper.checkboxIconStyle}/>
        }
        else if (checkboxStyle === 'box') {
            return checked ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankIcon />
        } else {
            return checked ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankIcon />
        }
    }

    return (
        <CustomWidthTooltip title={tooltip??''} placement={tooltipPlacement}>
        <div>
        <WrapperComponent onClick={handleClick} {...wrapper.props}>
            {(variant === 'simple' || variant === 'simpleNarrow') && 
                <Box className="cho__choiceItem" pl={1} pr={1} display="flex" alignItems={verticalAlign === 'top'? 'flex-start':'center'}>
                        <Box className="cho__checkboxWrapper">
                            <Checkbox
                                color="default"
                                sx={isSelected ? {
                                    color: checkboxColor === 'green' ? '#03c796' : null,
                                } : null}
                                icon={getCheckboxIcon(false)}
                                checkedIcon={getCheckboxIcon(true)}
                                checked={isSelected}
                                style={
                                    showIcon === false ? { 'visibility': 'hidden', width: '1px' } : null
                                }
                            />
                        </Box>
                        <Box pl={0.5} flexGrow={1} display="flex" justifyContent="center" flexDirection="column">
                            <Box color="text.primary" align="left" className="cho__titleWrapper"><Typography sx={titleSx}>{title}</Typography></Box>
                            {subtitle && (<Box color="text.secondary" sx={{ fontSize: '14px',}} align="left">{subtitle}&nbsp;</Box>)}
                            {isSelected === true && customInput === true && (
                                <>
                                    <TextField 
                                        onClick={handleTextFieldClick} 
                                        autoFocus={true} 
                                        fullWidth={true} 
                                        defaultValue={defaultValue}
                                        onChange={(e)=>onCustomInputChange(e.target.value)}
                                        placeholder={'Enter value'}
                                        InputProps={{
                                            sx: {fontSize: '16px'} //prevents auto pinch zoom
                                        }}
                                        />
                                </>
                            )}
                        </Box>
                    </Box>
            }
            {variant === 'box' && 
                <Box p={2} display="flex" flexDirection="column" flexGrow="1">
                    <Box display="flex" alignItems="center" mb={imageSrc || description ? 1:null} justifyContent={checkboxPosition === 'right'?'space-between':null}>
                        {showCheckbox && checkboxPosition === 'left' && <CardCheckbox isSelected={isSelected} showIcon={showIcon} checkboxStyle={checkboxStyle} checkboxColor={checkboxColor} />}
                        <Box component="span" ml={showCheckbox && checkboxPosition === 'left'? 1:0}>
                            {title && isString(title) ? <Typography sx={titleSx}>{title}</Typography> : title}
                        </Box>
                        {showCheckbox && checkboxPosition === 'right' && <CardCheckbox isSelected={isSelected} showIcon={showIcon} checkboxStyle={checkboxStyle} checkboxColor={checkboxColor} />}
                    </Box>
                    {imageSrc && 
                        <Box display="flex" flexDirection="column" 
                            flexGrow={description ? 0 : 1} 
                            alignItems="center" 
                            justifyContent="center" 
                            marginTop={smallImage?0:"-10px"}
                            pt={description ? 1.5 : 0}
                            pb={description ? 1.5 : 0}
                            sx={styling?.image}
                            >
                            <Image 
                                src={imageSrc} 
                                unoptimized={true} 
                                width={smallImage? '40px' : (imageWidth || "80px")} 
                                height={smallImage? '40px' : (imageHeight || "80px")} 
                                {...imageProps}
                            />
                        </Box>
                    }
                    {description && 
                        <Box color="text.secondary">
                            <MultilineTypography>{description}</MultilineTypography>
                        </Box>
                    }
                </Box>
            }
        </WrapperComponent>
           {afterContent}
        </div>
        </CustomWidthTooltip>
    );

}

const CardCheckbox = ({isSelected, showIcon, checkboxStyle, checkboxColor}) => {

    const sxColor = checkboxColor === 'green' 
        ? '#03c796'
        : null

    const color = checkboxColor === 'primary'
        ? 'primary'
        : 'default'


    if (checkboxStyle === 'circle') {
        return (
            <Checkbox
                className="checkbox"
                color={color}
                sx={{color: sxColor,}}
                icon={<CircleUnchecked style={{
                    fontSize: '18px',
                }}/>}
                checkedIcon={<CircleCheckedFilled style={{
                    fontSize: '18px',
                }}/>}
                checked={isSelected}
                style={{
                    padding: '0px',
                    'visibility': showIcon === false ? 'hidden' : null,
                    width: showIcon === false ? '1px' : null,
                }}
            />
        )
    } else if (checkboxStyle === 'standard') {
        return (
            <Checkbox
                className="checkbox"
                color={color}
                sx={{color: sxColor,}}
                icon={<></>} // no icon
                checkedIcon={<CheckIcon style={{
                    fontSize: '18px',
                }}/>}
                checked={isSelected}
                style={{
                    padding: '0px',
                    'visibility': showIcon === false ? 'hidden' : null,
                    width: showIcon === false ? '1px' : null,
                }}
            />
        )
    } else {
        return <>unknown checkbox style</>
    }

}

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 200,
    },
  });